import { memo, useEffect, useState, useCallback } from 'react';
import { Button, Pagination } from 'antd';
import { useSelector } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { getMySpaces, getSpaceBenefit } from '../../../services/space';
import default_space_bg from '../../../assets/images/profile_spaces_bg.png';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import {SPACE_LAUNCH_LINK_VALIDATION} from "../../../constants/space";
import CreateBenefit from './CreateBenefit';
import CustomPagination from '../../../components/commons/CustomPagination/CustomPagination';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const Benefits = () => {
    const [activeTab, setActiveTab] = useState('active');
    const [spaceUsername, setSpaceUsername] = useState(null);
    const currentLocation = useLocation();
    const [showCreateBenefit, setShowCreateBenefit] = useState(false)
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [ spaceBenefits, setSpaceBenefits ] = useState([]);
    const [ totalBenefits, setTotalBenefits ] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 5;

    const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

    useEffect(()=> {}, [totalBenefits])

    const onPageChange = (page) => {
        setCurrentPage(page);        
        console.log(page)
        const section = document.getElementById('draggable-cards');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onCancelCreate = () => {
        setShowCreateBenefit(false);
        fetchData();
    }
    const fetchData = async () => {
        if(spaceInfo){
            const data = {space_id: spaceInfo.id, page: currentPage, per_page: pageSize };
            const response = await getSpaceBenefit(data);
            setSpaceBenefits(response.space_benefits)
            setTotalBenefits(response.total_benefits)
        }
   
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, spaceInfo]);

    useEffect(() => {
        if(spaceInfo){                       
            fetchData();
        }                     
    }, [spaceInfo])

    useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setSpaceUsername(username);
            }
        }
    }
    )
	return (
        <>
            <div className="cs-dash-card block-full card">
            {!showCreateBenefit ? (
                <hgroup className="heading">
                    <h2>Manage Benefits</h2>
                    <p><a href="#link-via-admin" target="_blank">Benefits</a> customizable access, redemptions, privileges, and more to your Members. 
                    Each Benefit can be assigned multiple Memberships and Items, and also has an optional Benefits Post which displays in the Benefits section of your Space. 
                    There are many types of Benefits and you can add as many as you like. {getSitewideLink('learn_more_benefits')}</p>
                </hgroup>
            ) : (
                <hgroup className="heading">
                    <h2>Create New Access Benefit</h2>
                    <p>An <a href="#link-via-admin" target="_blank">Access</a> Benefit is a type of Benefit which allows you to provide access to specific content on your Space. You can apply this Benefit to any Memberships or Items and Members with that specific Membership or Item will gain access to the specified content. This is different than standard Members Only access, which provides access to associated content to Members with any Membership. {getSitewideLink('learn_more_benefits')}</p>
                </hgroup>
            )}
            {!showCreateBenefit && (
                <div id="cs-dash-card-nav-spaces" className="cs-nav-tabs">
                    <nav className="cs-nav-buttons-row block-full">
                        <div className="stack-row">
                            <a
                                onClick={() => {
                                    setActiveTab('active');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'active' ? 'active' : '')}
                            >
                                <span><p>Active</p></span>
                            </a>
                            <a
                                onClick={() => {
                                    setActiveTab('inactive');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'inactive' ? 'active' : '')}
                            >
                                <span><p>Inactive</p></span>
                            </a>
                            <a
                                onClick={() => {
                                    setActiveTab('posts');
                                }}
                                className={"button secondary gradient rounded-corners " + (activeTab === 'posts' ? 'active' : '')}
                            >
                                <span><p>2 Posts</p></span>
                            </a>
                        </div>
                    </nav>
                </div>
            )}
            </div>
           
            {!showCreateBenefit && activeTab === 'active' && (
            <div className="cs-dash-card block-full card">
                <div className="cs-stack-controlrows stack-form-col">
                    <div className="formrow split">
                        <h3 className="w-full">Active Benefits</h3>
                        <button type="button" className="button gradient secondary distribute" onClick={()=>setShowCreateBenefit(true)} id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-gift"></i><p>Create New</p><i className="fa-solid fa-caret-down"></i></span></button>                
                    </div>
                    <div className="formrow split">
                        <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-filter"></i><p>Filter</p><i className="fa-solid fa-caret-down"></i></span></button>
                        <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-bars-sort"></i><p>Sort</p><i className="fa-solid fa-caret-down"></i></span></button>
                    </div>
                </div>
            </div>
            )}
            {!showCreateBenefit && activeTab === 'active' && spaceBenefits && spaceBenefits.length > 0 && totalBenefits && (
            <>
                <div id="draggable-cards" className="cs-stack-form-drag-cards gap-gutter">
                    {spaceBenefits.map((benefit) => (
                        <div key={benefit.id} draggable="true" className="cs-dash-card block-full card cs-form-drag-card">
                            <div className="cs-stack-controlrows gap-form">
                                <div className="cs-form-drag-card-title">
                                    <div className="cs-controls-draggable">
                                        <p><i className="fa-regular fa-grip-lines"></i></p>
                                        <div className="cs-controls-draggable-buttons">
                                            <button><i className="fa-regular fa-angle-up"></i></button>
                                            <button><i className="fa-regular fa-angle-down"></i></button>
                                        </div>
                                    </div>
                                    <div className="cs-benefit-card-title stack-row">
                                        <h4>{benefit.name}</h4>
                                        <p>{benefit.description}</p>
                                    </div>
                                </div>
                                <div className="cs-stack-icons-attributes">
                                    <p><i className="fa-regular fa-id-card"></i></p>
                                    <p>{benefit.id}</p>
                                    <p><i className="fa-regular fa-hexagon-image"></i></p>
                                    <p>{benefit.imageCount}</p>
                                </div>
                                <div className="cs-access-card-controls stack-row">
                                    <a href="#" id="modal-open" className="button gradient secondary"><span><i className="fa-light fa-money-check-pen"></i></span></a>
                                </div>
                            </div> 
                        </div>
                    ))}
                </div>
                <div className="cs-pagination block-full">                    
                    <CustomPagination items={totalBenefits} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />                    
                </div>
            </>
            )}
            {showCreateBenefit && (
                <CreateBenefit onCancelCreate={onCancelCreate}></CreateBenefit>
            )}
        </>
    
	);
};

export default memo(Benefits);
