import React, {memo, useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';

import { MAGIC_OAUTH_KEY, MAGIC_CONNECT_NETWORK } from '../../../constants/common';
import { PRONOUNS } from '../../../constants/profile';
import routes from '../../../routes';
import ProfileEdit from './ProfileEdit';
import ManageContact from './ManageContact';
import ManageVisibility from './ManageVisibility';
import EditAboutSection from './EditAboutSection';
import ManageFollowers from './ManageFollowers';
import { shortenNumber } from '../../../utils/number';
import { getListDisplay } from '../../../utils/list';
import { getBackgroundFileTypeFromUrl } from '../../../utils/file';
import '../../../scss/style.scss'
import {Form} from 'antd';
import {GOOGLE_MAP_KEY, VALIDATE_MESSAGES} from "../../../constants/common";
import {createUpdateMembershipDetails, getMySpaces} from '../../../services/space';
const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
	network: MAGIC_CONNECT_NETWORK,
	locale: 'en_US',
	extensions: [new OAuthExtension()]
});

import FormFieldStacked from "../../../components/commons/FormFieldStacked";
import {editMemberQuestion, getMemberQuestions} from "../../../services/user";
import DropdownWithItems from "../../../components/commons/DropdownWithItems";
import {MEMBER_QUESTIONS_DROPDOWN} from "../../../constants/space";

const ProfileContent = (callback, deps) => {

	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedTab, setSelectedTab] = useState(routes.dashboard());

	const [cardBackground, setCardBackground] = useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
	const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
	const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);

	const [ownSpaces, setOwnSpaces] = useState([]);
	const [memberQuestions, setMemberQuestions] = useState([]);
	const [memberDefaultQuestions, setMemberDefaultQuestions] = useState([]);

	const [selectedSpace, setSelectedSpace] = useState(null);
	const [selectedSpaceTitle, setSelectedSpaceTitle] = useState(null);

	const navigate = useNavigate();
	const [form] = Form.useForm();

	const getPronounsDisplay = (value) => {
		const res = PRONOUNS.find((x) => x.value === value);
		return res.label;
	};

	const isHomeProfile = () => {};

	const getMemberQuestionsRequest = useCallback(async () => {
			try {
				if (!selectedSpace) {
					console.log('Space not found!')
					return;
				}

				if (!authUserInfo) {
					console.log('User not found!')
					return;
				}

				const data = {
					space_id: selectedSpace,
					user_id: authUserInfo.id,
				};

				const response = await getMemberQuestions(data);
				// console.log(response);
				if (response && response.result) {
					setMemberQuestions(response.member_questions)
					setMemberDefaultQuestions(response.default_questions)
				}
			} catch (error) {
			}
		},
		[
			authUserInfo, selectedSpace
		]
	);

	const getSpaces = useCallback(async () => {
		try {
			const params = {
				user_id: authUserInfo.id
			};
			const response = await getMySpaces(params);
			if (response && response.result) {
				const ownedSpaces = response.own_spaces;
				setOwnSpaces(ownedSpaces);
				setSelectedSpace(ownedSpaces && ownedSpaces.length > 0 ? ownedSpaces[0].id : null);
				setSelectedSpaceTitle(ownedSpaces && ownedSpaces.length > 0 ? ownedSpaces[0].name : null);
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	const checkLoginRedir = async () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const provider = queryParameters.get('provider') ?? 'No Provider';
		if (provider !== 'No Provider') {
			const result = await magicOauth.oauth.getRedirectResult();
			let profile = result.oauth.userInfo.profile ?? 'No Profile found';
			if (provider === 'tweeter' || provider === 'discord') {
				profile = result.oauth.userInfo.preferredUsername ?? 'No Profile found';
			}
			let email = authUserInfo.email ?? result.oauth.userInfo.email ?? 'No Email found';
			console.log(profile);
			console.log(email);

			let data = {
				email: email,
				social_type: provider,
				social_link: profile
			};
			await social_link(data);
		}
	};

	const handleSubmit = useCallback(async (values) => {
		console.log(values);
		// try {
		// 	if (spaceInfo) {
		// 		setIsEditQuestionLoading(true);
		//
		// 		const data = {
		// 			member_question_id: selectedQuestion.id,
		// 			question_text: values.question_text,
		// 			question_help_text: values.question_help_text,
		// 			is_visible: selectedDropdownVisibility === 'visible',
		// 			is_required: selectedDropdownRequired === 'required',
		// 			// order: values.order,
		// 			// question_type: values.question_type,
		// 		};
		//
		// 		const response = await editMemberQuestion(data);
		// 		if (response && response.result) {
		// 			const memberQuestion = response.member_question;
		//
		// 			// Update the memberQuestions array
		// 			const updatedMemberQuestions = memberQuestions.map(mq =>
		// 				mq.id === memberQuestion.id ? memberQuestion : mq
		// 			);
		//
		// 			setMemberQuestions(updatedMemberQuestions);
		//
		// 			setIsEditQuestionLoading(false);
		// 		} else {
		// 			setIsEditQuestionLoading(false);
		// 		}
		// 	}
		// } catch (error) {
		// 	setIsEditQuestionLoading(false);
		// }
		},[]
	)

	const linkGoogle = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'google' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkFacebook = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'facebook' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkTweeter = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'tweeter' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkDiscord = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'discord' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const setProfileData = async (data) => {
		if (data.profile.photo_large) {
			setProfileAssetType('image');
		}
		if (data.profile.video) {
			setProfileAssetType('video');
		}
		if (data.profile.background_photo) {
			setProfileBackgroundPhotoBlobPreview(data.profile.background_photo);
			const space_bg = {
				backgroundImage: 'url(' + data.profile.background_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (data.profile.background_video) {
			setProfileBackgroundVideoBlobPreview(data.profile.background_video);
			setCardBackgroundType('video');
		}
	};

	const navigateToProfile = async () => {
		navigate(routes.viewUserSpace() + (authUserInfo?.profile?.username ?? '') + '/about')
	};

	useEffect(() => {
		setSelectedTab(currentLocation.pathname);
		checkLoginRedir();

		if (authUserInfo && authUserInfo.profile) {
			setProfileData(authUserInfo).then();
		}
	}, [authUserInfo, currentLocation.pathname]);

	useEffect(()=>{
		if (authUserInfo) {
			getSpaces().then(() => {});
		}
	},[authUserInfo])

	useEffect(()=>{
		if (authUserInfo && selectedSpace) {
			getMemberQuestionsRequest().then(() => {});
		}
	},[selectedSpace])

	const handleSpaceChange = useCallback((label, value) => {
			setSelectedSpace(value);
			setSelectedSpaceTitle(label);
		},
		[]
	)

	const parsedSpaces = ownSpaces.map(space => ({ label: space.name, value: space.id.toString() }));
	console.log('ownSpaces: ' + ownSpaces);
	console.log('parsedSpaces: ' + parsedSpaces);

	return (
		<>
			{selectedTab === routes.profileEdit() && <ProfileEdit />}
			{selectedTab === routes.profileManageContact() && <ManageContact />}
			{selectedTab === routes.profileManageVisibility() && <ManageVisibility />}
			{(selectedTab !== routes.profileManageVisibility() && selectedTab !== routes.profileEdit() && selectedTab !== routes.profileManageContact()) && (
				<div id="csxl-profile-dashboard" className={(selectedTab !== routes.profileManageFollowers() && selectedTab !== routes.profileEdit()) ? 'cs-dash-card block-full card-base' : 'block-full'}>
					<div className={(selectedTab !== routes.profileManageFollowers() && selectedTab !== routes.profileEdit()) ? 'cs-profile-edit stack-col p-card' : 'dashboard-profileno-background'}>
						<div className="grid grid-cols-1">
							{selectedTab === routes.profileEdit() && <ProfileEdit />}
							{selectedTab === routes.profileManageContact() && <ManageContact />}
							{selectedTab === routes.profileManageVisibility() && <ManageVisibility />}
							{selectedTab === routes.profileEditAboutSection() && <EditAboutSection />}
							{selectedTab === routes.profileManageFollowers() && <ManageFollowers />}
							{(selectedTab === routes.profileCommunityBios()) && (
								<Form className="stack-col"
									  form={form}
									  name="form"
									  validateMessages={VALIDATE_MESSAGES}
									  onFocus={() => {
										  // setFormIsOnFocus(true);
									  }}
									  onBlur={() => {
										  // setFormIsOnFocus(false);
										  // handleSignUpFormOnBlur().then(() =>{});
									  }}
									  autoComplete="off"
									// onChange={handleFormChanges}
									  onFinish={handleSubmit}
								>
									<div className="cs-profile-edit stack-col p-card">
										<h2>Community Bios &amp; Info</h2>

										<DropdownWithItems
											title={`${selectedSpaceTitle ?? ''}`}
											dropDownClass="dropdown-bg"
											icon="fa-solid fa-solar-system"
											customButtonClass="icon-dropdown-gradient-alt secondary w-full"
											selectedValue={selectedSpace}
											listItems={parsedSpaces}
											isCharge={false}
											isRenew={true}
											onDropdownChange={(item) => handleSpaceChange(item.label, item.value)}
										/>

										{/*<button type="button" className="icon-dropdown-gradient-alt secondary"*/}
										{/*		aria-expanded="false" aria-haspopup="true"><span><i className="fa-solid fa-solar-system">*/}

										{/*</i><p>Friends &amp; Mike</p><i className="fa-solid fa-caret-down"></i></span></button>*/}

										{(memberDefaultQuestions && memberDefaultQuestions.length > 0) &&
											<hgroup className="heading">
												<h3>Community Bio</h3>
												<p>This is shown on your Profile and only visible to <a href="#" target="_blank">{selectedSpaceTitle}</a> Members.</p>
											</hgroup>
										}

										{/*<div className="form-textarea air">*/}
										{/*	<label htmlFor="shortbio">Community Bio</label>*/}
										{/*	<textarea id="shortbio" name="shortbio" placeholder="Optional, up to 512 characters including spaces." rows="3"></textarea>*/}
										{/*</div>*/}

										{(memberDefaultQuestions && memberDefaultQuestions.length > 0) && memberDefaultQuestions.map((memberQuestion, index) =>
												memberQuestion.is_visible && (
												<div className="form-textarea air">
													<label htmlFor="shortbio">{memberQuestion.question_text}</label>
													<Form.Item
														label="Short Bio"
														name="bio"
														validateTrigger="onBlur"
														className="w-full"
														rules={[
															{
																max: 512,
																message: 'Must not exceed 512 characters.'
															}
														]}
													>
														<FormFieldStacked
															formType='textarea'
															type="text"
															maxLength={512}
															// disabled={submitted}
															placeholder="Optional, up to 512 characters including spaces."
															rows={3}
														></FormFieldStacked>
													</Form.Item>
													{/*{form.getFieldsError()[3]?.errors.length > 0 && (*/}
													{/*	<div className="alert callout warning">*/}
													{/*		<div className="action">*/}
													{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
													{/*		</div>*/}
													{/*		<div className="content">*/}
													{/*			<div>*/}
													{/*				<h6>{form.getFieldsError()[3]?.errors}</h6>*/}
													{/*			</div>*/}
													{/*		</div>*/}
													{/*	</div>*/}
													{/*)}*/}
												</div>
												)
										)}


										{(memberQuestions && memberQuestions.length > 0) && memberQuestions.map((memberQuestion, index) =>
											memberQuestion.is_visible && (
												<div className="form-input air">
													<label htmlFor={'question_'+(index + 1)}>{memberQuestion.question_text}</label>
													<Form.Item
														label={memberQuestion.question_text}
														name={'question_'+(index + 1)}
														validateTrigger="onBlur"
														className="w-full"
														rules={[
															{ required: true },
															{
																max: 256,
																message: 'Must not exceed 256 characters.'
															}
														]}
													>
														<FormFieldStacked
															formType='input'
															type="text"
															maxLength={256}
															// disabled={submitted}
															placeholder={`${memberQuestion.question_help_text} ${memberQuestion.is_required ? 'Required.' : 'Optional.'}`}
														></FormFieldStacked>
													</Form.Item>
													{/*{form.getFieldsError()[1]?.errors.length > 0 && (*/}
													{/*	<div className="alert callout warning">*/}
													{/*		<div className="action">*/}
													{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
													{/*		</div>*/}
													{/*		<div className="content">*/}
													{/*			<div>*/}
													{/*				<h6>{form.getFieldsError()[1]?.errors}</h6>*/}
													{/*			</div>*/}
													{/*		</div>*/}
													{/*	</div>*/}
													{/*)}*/}
												</div>

												// <div className="form-input air">
												// 	<label htmlFor="q1">{memberQuestion.question_text}</label>
												// 	<input type="text" name="q1" id="q1" placeholder={`${memberQuestion.question_help_text} ${memberQuestion.is_required ? 'Required.' : 'Optional.'}`}
												// 	/>
												// </div>
											)
										)}


										{(memberQuestions && memberQuestions.length) > 0 &&
											<hgroup className="heading">
												<h3>Member Information</h3>
												<p>This information is private and only visible to the <a href="#" target="_blank">{selectedSpaceTitle}</a> team.</p>
											</hgroup>
										}

										{memberQuestions && memberQuestions.length > 0 && memberQuestions.map((memberQuestion, index) =>
												!memberQuestion.is_visible && (
													<div className="form-input air" key={index}>
														<label htmlFor={'hidden_question_' + (index + 1)}>
															{memberQuestion.question_text}*
														</label>
														<Form.Item
															label={memberQuestion.question_text}
															name={'hidden_question_' + (index + 1)}
															validateTrigger="onBlur"
															className="w-full"
															rules={[
															    { required: true },
															    {
															        max: 256,
															        message: 'Must not exceed 256 characters.'
															    }
															]}
														>
															<FormFieldStacked
																formType="input"
																type="text"
																maxLength={256}
																// disabled={submitted}
																placeholder={`${memberQuestion.question_help_text} ${memberQuestion.is_required ? 'Required.' : 'Optional.'}`}
															/>
														</Form.Item>
													</div>
												)
										)}


										<button
											className="primary-gradient"
											type="submit"
											// disabled={
											// 	isDeleteQuestionLoading || isEditQuestionLoading || !checkValidQuestionData()
											// }
										>
												<span>
													<p>
														{/*{(isEditQuestionLoading) && (*/}
														{/*	<i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>*/}
														{/*)}*/}
														Save
													</p>
												</span>
										</button>

									</div>
								</Form>
							)}
							{(selectedTab === routes.profile()) && (
								<div className="stack-buttons col">
									<a
										href={routes.profileEdit()}
										className="button gradient secondary"
									>
											<span>
												<p>
													Profile
												</p>
											</span>
									</a>

									<a
										href={routes.viewUserSpace() + (authUserInfo?.profile?.username ?? '') + '/about'}
										className="button gradient secondary"
									>
											<span>
												<p>
													About
												</p>
											</span>
									</a>

									{/* <a
											href={routes.profileEditAboutSection()}
											className="button gradient secondary"
										>
											<span>
												<p>
													Edit About Section
												</p>
											</span>
										</a> */}

									<a
										href={routes.profileManageFollowers()}
										className="button gradient secondary"
									>
											<span>
												<p>
													Followers
												</p>
											</span>
									</a>

									<a
										href={routes.profileManageContact()}
										className="button gradient secondary"
									>
											<span>
												<p>
													Contact
												</p>
											</span>
									</a>

									<a
										href={routes.profileManageVisibility()}
										className="button gradient secondary"
									>
											<span>
												<p>
													Visibility
												</p>
											</span>
									</a>
									<a
										href={routes.profileCommunityBios()}
										className="button gradient secondary"
									>
											<span>
												<p>
													Community Bios & Info
												</p>
											</span>
									</a>
								</div>

							)}
						</div>
					</div>
					{(selectedTab === routes.profile() || selectedTab === routes.profileCommunityBios()) && (
						<div className='cs-profile-preview rounded-r-card bg-cover-center' style={cardBackground} >
							{cardBackgroundType && cardBackgroundType === 'video' && (
								<video
									autoPlay
									muted
									loop
									className="absolute w-full h-full object-cover left-0 top-0  rounded-b-[8px] lg:rounded-bl-none lg:rounded-tl-none lg:rounded-r-[8px]"
								>
									<source
										src={profileBackgroundVideoBlobPreview}
										type="video/mp4"
									/>
								</video>
							)}
							<div className='cs-stack-profile-preview rounded-r-card stack-col p-card shader' >
								<div className='form-input-media lg circle air nohover' style={profileAssetType !== 'video' ? { backgroundImage: `url(${authUserInfo?.profile?.photo_thumb ?? ''})` } : {}}>
									{profileAssetType && profileAssetType === 'video'  && (
										<div className="video-profile-background-container">
											<video
												autoPlay
												muted
												loop
												className="form-input-media object-cover lg circle air nohover"
											>
												<source
													src={authUserInfo?.profile?.video ?? ''}
													type="video/mp4"
												/>
											</video>
										</div>
									)}
								</div>
								<p className="text-p1 active">
									{(authUserInfo?.profile?.username ?? '') && '@' + (authUserInfo?.profile?.username ?? '')}
								</p>
								<h1>
									{authUserInfo?.profile?.display_name ?? ''}
								</h1>
								<p className="text-p2 italic">
									{(authUserInfo && authUserInfo?.profile?.pronouns) && (
										<>
											{getListDisplay(
												PRONOUNS,
												authUserInfo?.profile?.pronouns ?? ''
											)}
										</>
									)}
								</p>
								<p className="italic">
									{authUserInfo?.profile?.intro ?? ''}
								</p>
								<div className='cs-stack-icons stack-row-wrap'>
									<i
										className="fa-brands fa-twitter isDisabled"

									></i>
									<i
										className="fa-brands fa-facebook isDisabled"

									></i>
									<i
										className="fa-brands fa-youtube isDisabled"

									></i>
									<i
										className="fa-brands fa-discord isDisabled"
									></i>
									<i className="fa-regular fa-globe isDisabled"></i>
								</div>
							</div>
						</div>
					)}

				</div>

			)}
		</>
	);
};

export default memo(ProfileContent);
