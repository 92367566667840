import { postRequest, getRequest } from './base';
import { RECAPTCHA_ACTION } from '../constants/recaptcha';

export const emailSignUp = async (data) => {
	// return postRequest('/api/user/email-signup', data, RECAPTCHA_ACTION.SIGN_UP);
	return postRequest('/api/user/email-signup', data)
};

export const register = async (data) => {
	// return postRequest('/api/user/register', data, RECAPTCHA_ACTION.REGISTER);
	return postRequest('/api/user/register', data);
};

export const emailForgotPassword = async (data) => {
	return postRequest('/api/user/forgot-password', data);
};

export const emailResetPassword = async (data) => {
	return postRequest('/api/user/reset-password', data);
};

/*
 * TOTP Google Authenticator
 */

export const enableOtp = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/enable', data);
};

export const getQrString = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/qr_string', data);
};

export const verifyMfaSetup = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/verify_mfa', data);
};

export const getBackupCodes = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/secret_key', data);
};

export const checkOtp = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/check_otp', data);
};

export const checkMfaEnabled = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/check_mfa_enabled', data);
};

export const requestSmsOtp = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/sms/request_sms_otp', data);
};

export const setupSms = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/sms/enable_sms', data);
};

export const disableSms = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/sms/disable_sms', data);
};

export const checkSmsEnabled = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/sms/check_sms_enabled', data);
};

export const dualCheck2FA = async (data) => {
	// RECAPTCHA_ACTION.REGISTER
	return postRequest('/api/user/mfa/dual_check_2fa', data);
};
// ******* End Of Google Authenticator *******

export const walletSetAccount = async (data) => {
	// RECAPTCHA_ACTION.WALLET_SETUP
	return postRequest('/api/user/wallet/set-account', data);
};

export const walletSetAccountPassword = async (data) => {
	// RECAPTCHA_ACTION.WALLET_PASSWORD_SETUP
	return postRequest('/api/user/wallet/set-password', data);
};

export const registerMagic = async (data) => {
	// RECAPTCHA_ACTION.MAGIC_REGISTER
	return postRequest('/api/user/register_magic', data);
};

export const getEmailVerification = async (params) => {
	// RECAPTCHA_ACTION.EMAIL_VERIFICATION
	return getRequest('/api/user/verify-email', params);
};

export const getForgotEmailVerification = async (params) => {
	// RECAPTCHA_ACTION.EMAIL_VERIFICATION
	return getRequest('/api/user/verify-forgot-password', params);
};

export const registerMemberProfile = async (data) => {
	// RECAPTCHA_ACTION.MEMBER_PROFILE_CREATE
	return postRequest('/api/user/profile/create', data);
};

export const updateProfile = async (data) => {
	// RECAPTCHA_ACTION.MEMBER_PROFILE_UPDATE
	return postRequest('/api/user/profile/update', data);
};

export const updateProfileAboutMe = async (data) => {
	// RECAPTCHA_ACTION.PROFILE_ABOUT_ME_UPDATE
	return postRequest('/api/user/profile/about_me/update', data);
};

export const getProfileAboutMe = async (params) => {
	return getRequest('/api/user/profile/about_me/get', params);
};

export const updateProfileVisibility = async (params) => {
	// RECAPTCHA_ACTION.PROFILE_VISIBILITY_UPDATE
	return postRequest('/api/user/profile/visibility/update', params);
};

export const updateUserSettingsBasics = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_BASICS_UPDATE
	return postRequest('/api/user/profile/settings/basics/update', params);
};

export const updateUserSettingsSecurity = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_SECURITY_UPDATE
	return postRequest('/api/user/profile/settings/security/update', params);
};

export const updateUserSettingsContacts = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_CONTACTS_UPDATE
	return postRequest('/api/user/profile/settings/contacts/update', params);
};

export const updateUserSettings2FASMS = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_2FA_SMS_UPDATE
	return postRequest('/api/user/profile/settings/2fa/sms/update', params);
};

export const updateUserSettings2FAGoogleCode = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_2FA_GOOGLE_CODE_UPDATE
	return postRequest('/api/user/profile/settings/2fa/google_code/update', params);
};

export const updateUserSettings2FAStatus = async (params) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_2FA_STATUS_UPDATE
	return postRequest('/api/user/profile/settings/2fa/status/update', params);
};

export const updateUserNotificationPreferences = async (params) => {
	// RECAPTCHA_ACTION.USER_NOTIFICATION_PREFERENCES_UPDATE
	return postRequest('/api/user/notification/preferences/update', params);
};

export const getUserByUsername = async (params) => {
	return getRequest('/api/user/profile/by_username/get', params);
};

export const getUserSpaceMembershipClaim = async (params) => {
	return getRequest('/api/user/space/membership/claim', params);
};

export const getUserSpaceMembership = async (params) => {
	return getRequest('/api/user/space/membership/get', params);
};

export const getUserSpaceItemClaim = async (params) => {
	return getRequest('/api/user/space/item/claim', params);
};

export const registerUserWallet = async (data) => {
	// RECAPTCHA_ACTION.USER_SETTINGS_ADD_WALLET
	return postRequest('/api/user/profile/settings/register_wallet', data);
};

export const getSavedWallets = async (data) => {
	return getRequest('/api/user/profile/settings/get_wallet', data);
};

export const getMyCollections = async (params) => {
	return getRequest('/api/user/my_collections/get', params);
};

export const getMySpaceCollections = async (params) => {
	return getRequest('/api/user/my_space_collections/get', params);
};

export const getUserSpaces = async (params) => {
	return getRequest('/api/user/spaces/list/get', params);
};

export const addToTop8 = async (params) => {
	return postRequest('/api/user/top8/post', params);
};

export const removeToTop8 = async (params) => {
	return postRequest('/api/user/top8/delete', params);
};

export const getTop8 = async (params) => {
	return getRequest('/api/user/top8/get', params);
};

export const createMemberQuestion = async (params) => {
	return postRequest('/api/user/member_question/create', params);
};

export const editMemberQuestion = async (params) => {
	return postRequest('/api/user/member_question/edit', params);
};

export const deleteMemberQuestion = async (params) => {
	return postRequest('/api/user/member_question/delete', params);
};

export const getMemberQuestions = async (params) => {
	return getRequest('/api/user/member_questions/get', params);
};

