import React, { memo, useCallback, useEffect, useState } from 'react';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, Popover, Listbox } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import co_logo from '../../../assets/images/cs-logo.svg';
import routes from '../../../routes';
import './Header.scss';
import { useNavigate } from 'react-router-dom';
import searchParams from "stream-http";
import {Button} from "antd";
import default_user_profile from "../../../assets/images/default_user_profile.png";
import ReactPlayer from "react-player/lazy";
import {getSpaceRoute} from "../../../utils/redirects";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }
  

const Header = (props) => {
	const {
		spaceData,
		globalActiveTab,
		updateGlobalActiveTab,
		viewProfileUsername
	} = props;
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();
	const path = currentLocation.pathname;
	const [spaceUsername, setSpaceUsername] = useState(null);
	const [spacePath, setSpacePath] = useState(null);
	const [isDashboardView, setIsDashboardView] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const urlTab = searchParams.get('tab');
	const [initialLoad, setInitialLoad] = useState(true);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

	const goHome = useCallback(async () => {
		const path = currentLocation.pathname;

		if (authUserInfo) {
			window.location.replace(routes.dashboard());
		} else {
			if (path !== routes.login()) {
				window.location.replace(routes.login());
			}
		}
	}, [currentLocation.pathname, authUserInfo]);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		document.body.classList.toggle('lock-body');
	};

	const toggleDropdownMenu = () => {
		setIsDropdownMenuOpen(!isDropdownMenuOpen);
	};

	useEffect(() => {
		if (authUserInfo && authUserInfo.profile) {
			setProfileAssetType(null);
			if (authUserInfo.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo.profile.video) {
				setProfileAssetType('video');
			}
		}
	}, [authUserInfo, loading, profileAssetType]);

	const setActiveTabChange = (item) => {
		updateGlobalActiveTab(item);
		// setCurrentPage(0);
		if (item === 'home' || item === 'members') {
			// force page url navigate to reset location path when going back to home
			navigate((spaceData && spaceData.username ? routes.viewUserSpace() + spaceData.username + (viewProfileUsername ? '/@' + viewProfileUsername : '') : '/'), { replace: true });
		}
	};

	const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

	const tabs = ['Explore', 'Create', 'Learn'];

	useEffect(() => {
		const path = window.location.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let directory = path.replace('/@', '');
			const split = directory.split('/');
			const username = split[0];
			setSpaceUsername(username);
		}

		// if (spaceUsername != null) {
		// 	if (globalActiveTab === 'updates') {
		// 		// getUpdates(spaceData).then();
		// 		if (!path.includes('update')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/update');
		// 		}
		// 	} else if (globalActiveTab === 'home') {
		// 		if (!path.includes('home') && !path.includes('verify-email')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : ''));
		// 		}
		// 	} else if (globalActiveTab === 'benefits') {
		// 		if (!path.includes('benefit')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/benefit');
		// 		}
		// 	} else if (globalActiveTab === 'members') {
		// 		if (!path.includes('member')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/member');
		// 		}
		// 	} else if (globalActiveTab === 'support') {
		// 		if (!path.includes('support')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/support');
		// 		}
		// 	} else if (globalActiveTab === 'about') {
		// 		if (!path.includes('about')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/about');
		// 		}
		// 	} else if (globalActiveTab === 'contact') {
		// 		if (!path.includes('contact')) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/contact');
		// 		}
		// 	} else if (globalActiveTab === '' &&
		// 		((path === ('/@' + spaceUsername + '/@' + viewProfileUsername)) ||  // Check the path is /@spaceUsername/@profileUsername
		// 		path === ('/@' + spaceUsername))) // Check the path is /@spaceUsername or /@profileUsername
		// 	{
		// 		// If the condition is meet set the active tab into about and reload the page in the about tab.
		// 		if (spaceData) {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername);
		// 			setActiveTabChange('');
		// 		} else {
		// 			window.history.replaceState(null, null, '/@' + spaceUsername + (viewProfileUsername ? '/@' + viewProfileUsername : '') + '/about');
		// 			setActiveTabChange('about');
		// 		}
		// 	}
		// }
		// console.log(spaceData)

		const availableTabs = ['home', 'updates', 'benefits', 'members'];
		if (initialLoad) {
			setInitialLoad(false);

			if (availableTabs.includes(urlTab)) {
				setActiveTabChange(urlTab);
				searchParams.delete('tab');
				setSearchParams(searchParams);
			}

			if (urlTab != null) {
				urlTab.map((tab) => console.log('tab: ' + tab));
			}
		}

		if (globalActiveTab === 'update_content') {
			if (availableTabs.includes(globalActiveTab)) {
				let baseUrl = '/@' + spaceUsername;
				// if (globalActiveTab === 'home') {
				// 	baseUrl = baseUrl;
				// }
				if (globalActiveTab === 'updates') {
					baseUrl = baseUrl + '/update';
				}
				if (globalActiveTab === 'benefits') {
					baseUrl = baseUrl + '/benefit';
				}
				if (globalActiveTab === 'members') {
					baseUrl = baseUrl + '/member';
				}
				if (globalActiveTab === 'support') {
					baseUrl = baseUrl + '/support';
				}
				if (globalActiveTab === 'about') {
					baseUrl = baseUrl + '/about';
				}
				if (globalActiveTab === 'contact') {
					baseUrl = baseUrl + '/contact';
				}
				window.history.replaceState(null, null, baseUrl);
				navigate(baseUrl);
			}
		}

	}, [spaceData, initialLoad, urlTab, globalActiveTab, spaceUsername]);

	useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setSpacePath(usernameExp[1])
				setIsDashboardView(path.startsWith('/@') && usernameExp[1] !== 'about');
				setSpaceUsername(username);
			}else{
				setIsDashboardView(false);
			}
		}
	}, [])

	return (
		<header id="cs-platform-header" className="cs-header header-base theme">
			<div id="header-frame" className="header-flex p-header-safeview">
				<nav>
					<div id="cs-platform-header-logo" className="flex">
						{spaceData ? (
							<>
								<a
									href={getSpaceRoute(routes.viewUserSpace() + spaceData?.username, routes.viewUserSpace() + spaceData?.username)}
									target="_self"
									onClick={(event) => setActiveTabChange('home')}>
									<img src={co_logo} alt={spaceData?.name}/>
								</a>
							</>
						) : (
							<>
								<a
									href={'/'}
									target="_self"
									onClick={(event) => setActiveTabChange('home')}>
									<img src={co_logo} alt={spaceData?.name}/>
								</a>
							</>
						)}

					</div>

					<div id="cs-platform-header-nav" className="hidden lg:flex gap-gutter">
						{tabs.map((tab, index) => (
							<p key={index}>
								<a
									// onClick={() => setActiveTabChange(tab.id)}
								>
									{tab}
								</a>
							</p>
						))}
					</div>

					<div id="cs-space-header-user-nav">
						{!authUserInfo && (
							<div className="cs-stack-user-nav-public">
								<a href={routes.login()} target="_self" className="button-secondary-gradient"><span><p>Login</p></span></a>
								<a href={routes.signUp()} target="_self" className="button-primary-gradient"><span><p>Sign Up</p></span></a>
							</div>
						)}
						{authUserInfo && (
							<div className="cs-stack-user-nav-member">
								<a id="header-user-nav-menu-toggle" className="button-icon-round-gradient"><span><p><i className="fa-regular fa-ellipsis"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-search"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-bell"></i></p></span></a>
								<a className="button-icon-round-gradient"><span><p><i className="fa-regular fa-comments"></i></p></span></a>
								<a onClick={toggleDropdownMenu} id="user-menu-toggle" className={`avatar-gradient`}>
									{profileAssetType && profileAssetType === 'video' ? (
										<ReactPlayer
											playing={true}
											loop={true}
											muted={true}
											controls={false}
											width="100%"
											height="100%"
											className={`videoPreview`}
											url={authUserInfo.profile.video}
										/>
									) : (
										<img
											src={authUserInfo && authUserInfo?.profile?.photo_thumb ? authUserInfo?.profile?.photo_thumb : default_user_profile}
											alt="Image of cs_profile_username"
										/>
									)}
								</a>
							</div>
						)}
					</div>

					{/* CSJS PUBLIC Header Nav Menu Button - Only visible/exists if User is Public / Logged Out. If present, the !hidden class (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage. */}
					{!authUserInfo && (
						<div className="cs-stack-user-nav-public-menu-toggle">
							<a id="header-nav-menu-toggle" onClick={toggleMenu} className="toggle-menu-gradient">
								<i id="header-nav-menu-open" className={`fa-solid fa-bars ${isMenuOpen ? 'hide' : ''}`}/>
								<i id="header-nav-menu-close" className={`fa-solid fa-xmark ${isMenuOpen ? '' : 'hide'}`}/>
							</a>
						</div>
					)}

				</nav>
			</div>

			{/* SECTION Nav Mobile Menu */}
			{/* CSJS Current toggle is a simple implementation for demo only. #header-nav-menu div can be implemented using other methods. */}
			<div id="header-nav-menu" className={`cs-nav-menu py-header ${isMenuOpen ? '' : 'hide'}`}>
				<nav className="flex flex-col">

					<a href="/" className="nav-gradient" target="_self"><p>Home</p></a>
					<a
						href=""
						className="nav-gradient" target="_self">
						<p>Explore</p>
					</a>
					<a
						href=""
						className="nav-gradient" target="_self">
						<p>Create</p>
					</a>
					<a
						href=""
						className="nav-gradient" target="_self">
						<p>Learn</p>
					</a>
					<div className="h-element-min"></div>
					{/* CSJS Only visible/exists if User is Public / Logged Out. If present, the !hidden class (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage. */}
					<a href={routes.login()} className="nav-gradient" target="_self"><p>Login</p></a>
					{/* CSJS Only visible/exists if User is Public / Logged Out. If present, the !hidden class (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage. */}
					<a href={routes.signUp()} className="nav-gradient" target="_self"><p>Sign Up</p></a>
				</nav>
			</div>
			{/* !SECTION PUBLIC Mobile Menu */}

			{/* SECTION MEMBER User Menu */}
			{/* CSJS Current toggle is a simple implementation for demo only. */}
			<div id="cs-platform-user-menu" className={`block-base mr-section-safeview ${isDropdownMenuOpen ? '' : 'hide'} z-10`}>
				<nav>
					<Link to={routes.dashboard()} target="_self">
						<div>
							<span><i className="fa-light fa-chart-tree-map"></i></span>
							<p>Dashboard</p>
						</div>
					</Link>
					{/*<Link target="_self">*/}
					{/*	<div>*/}
					{/*		<span><i className="fa-light fa-list-ul"></i></span>*/}
					{/*		<p>Activity</p>*/}
					{/*	</div>*/}
					{/*</Link>*/}
					<Link to={routes.spaces()} target="_self">
						<div>
							<span><i className="fa-light fa-solar-system"></i></span>
							<p>Spaces</p>
						</div>
					</Link>
					<Link to={routes.collections()} target="_self">
						<div>
							<span><i className="fa-light fa-grid"></i></span>
							<p>Collections</p>
						</div>
					</Link>
					<Link to={routes.profile()} target="_self">
						<div>
							<span><i className="fa-light fa-id-badge"></i></span>
							<p>Profile</p>
						</div>
					</Link>
					<Link to={routes.settings()} target="_self">
						<div>
							<span><i className="fa-light fa-gear"></i></span>
							<p>Account</p>
						</div>
					</Link>
					<Link to={routes.logout()} target="_self">
						<div>
							<span><i className="fa-light fa-arrow-right-from-bracket"></i></span>
							<p>Log Out</p>
						</div>
					</Link>
				</nav>
			</div>
			{/*	!SECTION MEMBER Mobile Menu*/}

		</header>
	);
};
export default memo(Header);
