import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Form, Input} from 'antd';
import Alert from 'react-bootstrap/Alert';
import TextareaAutosize from 'react-textarea-autosize';
import {VALIDATE_MESSAGES} from '../../../../constants/common';
import {CurrencyType} from "../../../../utils/MembershipItemUtils";
import {CustomValidators} from "../../../../utils/CustomValidators";
import {setBenefitMonthlyPrice} from "../../../../components/commons/helpers/MembershipItemHelpers";
import routes from "../../../../routes";
import {useNavigate, useParams} from "react-router-dom";

const AddMembershipBenefitTraits = (props) => {
    const { space_username } = useParams();
    const navigate = useNavigate();
    const {membershipData, spaceInfo, authUserInfo} = props;
    const [submitted, setSubmitted] = useState(false);

    const saveContinue = () => {
        const url = routes.createSpaceBasicCompletePage();
        navigate(url.replace(':space_username', space_username));
    };

    useEffect(() => {

    }, [authUserInfo, spaceInfo, membershipData]);

    return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                <div className="centerframe-grid gap-2gutter">
                    <div className="block-full">
                        <div className="cs-stack-heading">
                            <div className="stack-row split">
                                <h1>Add Benefits to Your Membership</h1>
                                <h3>Step 4 of 5</h3>
                            </div>
                            <p><a href="link-via-admin" target="_blank">Benefits</a> provide customizable access, redemptions, privileges, and perks to your Members. There are many types of Benefits and you can add as many as you like. You can customize them to easily integrate your community into your business. <a href="link-via-admin" target="_blank">Learn more about Benefits and how they work.</a></p>
                        </div>
                        <div className="cs-stack-form-guided">
                            <div className="formrow title">
                                <div>
                                    <h2>MEMBERSHIPNAME Benefits</h2>
                                    <p>Required</p>
                                </div>
                                <a href="#link-via-admin" className="button-secondary-gradient"><span><p>Space Creation Guide <i className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>
                            </div>
                            <p>You can add Benefits to customize this Membership. You can also create different Memberships with different Benefits and pricing to offer multiple options to your community. All Memberships include the basic Member Access Benefit. Any Benefit you create can be added to multiple Memberships and Items so you don’t need to create duplicates. You can create, edit, and add Benefits at any time to create more value and enhance your Members’ experience.</p>
                            <div className="formrow">
                                <a href="cs-create-space-homepage.html" className="button-secondary-gradient"><span><p>Member Access</p></span></a>
                                <div>
                                    <p>The Member Access Benefit provides basic “Members Only” access to Updates and Members sections in your Space. This is included in every Membership.</p>
                                </div>
                            </div>
                            <div className="formrow">
                                <button type="button" className="button-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true"><span><i className=""></i><p>VIP Access</p><i className="fa-solid fa-caret-down"></i></span></button>
                                <div>
                                    <p>You can remove this Benefit using the dropdown option. The Benefit will still be available to add later and can be managed from your Dashboard.</p>
                                </div>
                            </div>
                            <div className="formrow">
                                <div className="cs-stack-form-inputs stack-row">
                                    <div className="dropdown-menu-set">
                                        <button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-3"><span><i className="fa-light fa-gift"></i><p>Create New Benefit</p><i className="fa-solid fa-caret-down"></i></span></button>
                                        <ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-3">
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-menu-set">
                                        <button type="button" className="icon-dropdown-gradient-alt secondary active" aria-expanded="false" aria-haspopup="true" id="dropdown-button-4"><span><i className="fa-light fa-plus"></i><p>Add Existing Benefit</p><i className="fa-solid fa-caret-down"></i></span></button>
                                        <ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-4">
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                            <li role="option" tabIndex="-1">Value</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <p>Create a new Benefit by selecting a Benefit type then configuring it. You can also add an existing Benefit to this Membership. <a href="link-via-admin" target="_blank">Learn about different Benefit types.</a></p>
                                </div>
                            </div>
                            <div className="formrow">
                                <a onClick={saveContinue}className="button-primary-gradient">
                                    <span><p>
                                        {submitted && (
                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                        )}
                                        Save & Continue
                                    </p></span>
                                </a>
                                <div className="cs-formrow-helper-none">
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default memo(AddMembershipBenefitTraits);
