import { memo, useEffect, useState, useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import {
    createSpaceBenefit,
getSpaceAccessGroup,
} from '../../../../services/space';
import { useDispatch, useSelector } from 'react-redux';
import AccessGroupModal from "../../../../components/commons/Modals/AccessGroupModal/AccessGroupModal";
import DropdownWithItems from "../../../../components/commons/DropdownWithItems"
import CreateAccessGroupModal
	from "../../../../components/commons/Modals/CreateAccessGroupModal/CreateAccessGroupModal";



const CreateBenefit = (props) => {
    const { onCancelCreate } = props;
    const [createBenefitForm] = Form.useForm();
    const [formHasError, setFormHasError] = useState(true);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const [accessGroupList, setAccessGroupList] = useState([]);
    const [accessGroupModalOpen, setAccessGroupModalOpen] = useState(false);
    const [selectedAccessGroups, setSelectedAccessGroups] = useState([]);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState([]);
    const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [selectedButtonSetting, setSelectedButtonSetting] = useState()
    const [filteredAccessGroupList, setFilteredAccessGroupList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const dummyButtonOptions = [
        { label: 'Default', value: 'default' },
        { label: 'Benefits Post', value: 'benefits_post' },
        { label: 'Custom URL', value: 'custom_url' }
    ];

    const handleFormOnBlur = useCallback(async () => {
		await createBenefitForm
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});

	}, []);
    
	const updateAccessGroup = (group) => {
		getSpaceAccessGroups().then(r => {});
		selectedAccessGroups.push({id: group.id, label: group.name, value: group.id});
	}
    
	const handleSubmit = useCallback(
		async (values) => {
            console.log(spaceInfo)
            console.log(createBenefitForm.getFieldsValue())
            const data = { ...createBenefitForm.getFieldsValue(), space_id: spaceInfo.id };
            const response = await createSpaceBenefit(data);
            onCancelCreate(false)
            console.log(response)
        })

    const handleFormChanges = useCallback(async () => {
        await createBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const getSpaceAccessGroups = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					const space_param = {}
					space_param.space_id = spaceInfo.id;
                   
					const responseGetSpaceAccessGroups = await getSpaceAccessGroup(space_param);
					if (responseGetSpaceAccessGroups && responseGetSpaceAccessGroups.result) {
						let groupArray = [];
						const groupList = responseGetSpaceAccessGroups.access_groups;
						if (groupList && groupList.length > 0) {
							groupList.map((group) => {
								groupArray.push({id: group.id, label: group.name, value: group.id})
							})
						}
						setAccessGroupList(groupArray);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo]
	);

    useEffect(() => {
        let groupList = [
            { label: 'Select Access Group', value: 'default', disabled: true },
            { label: 'Create New Access Group', value: 'create' }
        ];
        if (accessGroupList && accessGroupList.length > 0) {
            accessGroupList.map((accessGroup) => {
                groupList.push(accessGroup);
            })
        }
        // if (selectedAccessGroups && selectedAccessGroups.length > 0) {
        //     selectedAccessGroups.map((selectedAccessGroup) => {
        //         groupList.map((gList) => {
        //             if (selectedAccessGroup.value === gList.value)
        //                 groupList.shift(accessGroup);
        //         })
        //     })
        // }
        setFilteredAccessGroupList(groupList);
    }, [accessGroupList]);

    const filterDropdownChange = (value) => {
        if (value === 'create') {
            setCreateAccessGroupModalOpen(true);
            setModalOpen(false);
        } else {
            let selected = selectedAccessGroups;
            const selectedGroup = selectedAccessGroups.find((access) => access.value === value);
            const access = accessGroupList.find((access) => access.value === value);
            console.log(access)
            console.log(value)
            setSelectedAccessGroup(access.id)
            setSelectedAccessGroups(selected);
            createBenefitForm.setFieldsValue({ access_settings: access.id });
        }
    };

    const handleButtonSettingChange = (value) => {
        
        setSelectedButtonSetting(value)
        createBenefitForm.setFieldsValue({ button_settings: value });
    };
    

    useEffect(() =>{getSpaceAccessGroups()},[])
    useEffect(() =>{console.log(accessGroupList)},[accessGroupList])


    return (
        <div className="cs-dash-card block-full card">
                <Form
						form={createBenefitForm}
						name="createBenefitForm"
						id="createBenefitForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocus(true);
						}}
						onBlur={() => {
							// setTimeout(() => {
							setFormIsOnFocus(false);
							handleFormOnBlur().then(r => {});
							// }, 200);
						}}
						onChange={handleFormChanges}
						onFinish={handleSubmit}
						className={formIsOnFocus ? 'hide-antd-error-messages stack-col' : 'stack-col'}
					>
                    <>
							{alert && (
								<div className="grid grid-cols-12 gap-8 mt-5">
									<div className="col-span-8">
										<Alert
											key={alert.variant}
											variant={alert.variant}
											className={
												'custom-alert ' +
												(alert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{alert.message}
										</Alert>
									</div>
								</div>
							)}
						</>
                    {/* <form className="stack-col"> */}
                    <div className="formrow title">
                            <h3>New Access Benefit</h3>
                            <p>Provide content and channel access</p>
                        </div>
                            <Form.Item
                                label="Name"
                                name="name"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                <div className="formrow split">
                                    <div className="form-input gradient"><span><label htmlFor="name">Name</label>
                                        <input id="name" name="text" type="text" required="" placeholder="Up to 32 characters."/></span>
                                    </div>    
                                    <div><p className='text-sm text-color-secondary italic'>This will be listed on the Product page of Memberships or Items with this Benefit.</p></div>
                                </div>
                            </Form.Item>
                            
                            <Form.Item
                                label="Details"
                                name="details"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                <div className="formrow split">
                                    <div className="form-input gradient"><span><label htmlFor="details">Details</label>
                                    <input id="details" name="text" type="text" required="" placeholder="Up to 160 characters."/></span></div>
                                    <div><p className='text-sm text-color-secondary italic'>This Details text with display next to the Benefit Name and should communicate what the Benefit provides.</p></div>
                                </div>
                            </Form.Item>    

                        <div className="formrow title">
                            <h4>Access Settings</h4>
                            <p>Specify an existing Access Group or create a new one</p>
                        </div>
                        <Form.Item
                                label="Access Settings"
                                name="access_settings"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                            <div className="formrow split">
                                {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Select Access Group</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                                <DropdownWithItems
                                    title="Select Access Group"
                                    customButtonClass="button gradient secondary dropdown w-full"
                                    selectedValue={selectedAccessGroup}
                                    listItems={filteredAccessGroupList}
                                    onDropdownChange={(value) => { filterDropdownChange(value) }}
                                />
                                <div><p  className='text-sm text-color-secondary italic'>Memberships or Items with this Benefet will be added to the specified Benefit Group.</p></div>
                            </div>
                            {accessGroupModalOpen && (
                                <AccessGroupModal
                                    modalOpen={accessGroupModalOpen}
                                    setModalOpen={setAccessGroupModalOpen}
                                    accessGroupList={accessGroupList}
                                    selectedAccessGroups={selectedAccessGroups}
                                    setSelectedAccessGroups={setSelectedAccessGroups}
                                    setCreateAccessGroupModalOpen={setCreateAccessGroupModalOpen}
                                    header_title={''}
                                    spaceID={spaceInfo.id}
                                    userID={authUserInfo.id}
                                />
                            )}
                            {createAccessGroupModalOpen && (
                                <CreateAccessGroupModal
                                    modalOpen={createAccessGroupModalOpen}
                                    setModalOpen={setCreateAccessGroupModalOpen}
                                    spaceMembership={spaceInfo.memberships}
                                    spaceItems={spaceInfo.items}
                                    updateAccessGroupList={updateAccessGroup}
                                    setAccessGroupModalOpen={setAccessGroupModalOpen}
                                    spaceID={spaceInfo.id}
                                    userID={authUserInfo.id}
                                />
                            )}
                        </Form.Item>

                        <div className="formrow title">
                            <h4>Button Settings</h4>
                            <p>Set how the button for this Benefit works</p>
                        </div>
                        <Form.Item
                                label="Button Settings"
                                name="button_settings"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                            <div className="formrow split">
                                {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Default</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                                <DropdownWithItems
                                    title="Select Button Setting"
                                    customButtonClass="button gradient secondary dropdown w-full"
                                    selectedValue={selectedButtonSetting}
                                    listItems={dummyButtonOptions}
                                    onDropdownChange={(value) => { handleButtonSettingChange(value) }}
                                />
                                <div><p className='text-sm text-color-secondary italic'>Default opens a list of accessible content. Benefits Post links to a selected post. Custom URL opens a specified link.</p></div>
                            </div>
                        </Form.Item>
                        <Form.Item
                                label="Note"
                                name="note"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                            <div className="formrow split">
                                <div className="form-input gradient"><span><label htmlFor="note">Note</label>
                                <input id="note" name="text" type="text" placeholder="Optional, up to 160 characters."/></span></div>
                                <div><p className='text-sm text-color-secondary italic'>This private text is only shown to the Member when using the Benefit with the Default or Custom URL Button Setting.</p></div>
                            </div>
                        </Form.Item>
                        {/* <Form.Item className="stack-buttons col">
                            <button
                                className="primary-gradient w-full"
                                onClick={handleSubmit}
                            >
                                <span><p>Create Benefit</p></span>
                            </button>
                            <a href="#"  onClick={()=>onCancelCreate(false)} className="button gradient secondary"><span><p>Cancel</p></span></a>
                        </Form.Item> */}
                        <div className="stack-buttons col">
                            <a onClick={handleSubmit} className="button gradient"><span><p>Create Benefit</p></span></a>
                            <a onClick={()=>onCancelCreate(false)} className="button gradient secondary"><span><p>Cancel</p></span></a>
                        </div>
                    </Form>
                </div>
    )
};

export default memo(CreateBenefit);